<!--
<template><fieldRowHeader></fieldRowHeader>
{$fieldAttrInput=getfieldAttrInput(fieldAttributes)}
<fieldsValuesTdHeader></fieldsValuesTdHeader>
<div class="PrintDiv" style="height: 150px;overflow-y: scroll;">
  <table class="">
    <tr style="background-color: #848484;color:#ffffff;height:0.5em;">
      <td>Login Time</td>
      <td>IP</td>
      <td>Browser_Version</td>
      <td>OS</td>
    </tr>
     v-for="(inDate, dateValue) in fieldAttributes.value"
      <tr {if $dateValue eq 0} style="background-color: rgba(78, 180, 255, 0.72);" title="First Row is Registration History" {else} title="Login History"{/if}">
        <td>{$inDate.n_loginTime|date_format:'%d-%m-%Y %k:%M:%S'}</td>
        <td>{$inDate.c_ip}</td>
        <td>{$inDate.c_browser}</td>
        <td>{$inDate.c_os}</td>
      </tr>
{/foreach}
  </table>
</div>
</td></template>-->
<template>
    <div>
     </div>
</template>
<script>
  export default {
    data:function(){
      return {
       };
    },
    methods: {

    },
    props: ["field","templateContent","valueLine"]
  }
</script>
